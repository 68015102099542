* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "poppins";
}

html {
  /* overflow-x: hidden; */
  font-family: "poppins";
  /* flex-direction: column; */
  flex: 1 1 auto;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-right: 0px !important;
  padding: 0px !important;
  font-family: "poppins";
}

img {
  max-width: 100%;
}

#root {
  overflow-x: hidden;
  font-family: "poppins";
  height: 100%;

  flex: auto;

  /* width: 100%;
  height: auto; */
}

button:focus {
  outline: none;
}

a {
  text-decoration: none !important;
}

div.MuiPopover-root div.MuiPaper-root {
  border-radius: 5px;
}

div.MuiPopover-root div.MuiPaper-root ul li {
  border-radius: 5px;
  margin-bottom: 10px;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  background-color: #f2f2f2;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #263661;
}

div.MuiPopover-root div.MuiPaper-root ul li:first-of-type {
  margin-top: 10px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px unset;
  box-shadow: 0 0 0 30px unset;
}

::-webkit-scrollbar {
  width: 0.8em;
  height: 8px;
}
/* ::marker {
  color: "red";
} */
::-webkit-scrollbar-thumb {
  background-color: #1d4f91;
  border-radius: 6px;
  border: 2px solid #f9f9ff;
}
div.MuiPopover-root div.MuiPaper-root ul li {
  background-color: white !important;
  text-transform: capitalize;
  font-weight: 600 !important;
}
/* div.MuiDialog-paperWidthSm {
  height: 50% !important;
} */
div.toolTipContainer {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 36px;
}
div.toolTip {
  padding-top: 10px;
  font-size: 14px;
  display: flex;
}
div.toolTipData {
  font-weight: 600 !important;
  width: 33%;
}
.MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root {
  overflow: hidden;
}
.MuiDialog-paperWidthMd {
  min-width: 960px;
}
